/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

const NAV_LIST = [
  { url: '/blog-details', title: 'ブログ' },
  { url: '/project-list', title: '案件一覧' },
]

const Head = () => {
  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }

  return (
    <div
      style={{
        width: '100%',
        margin: '0 auto', // 중앙 정렬
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <header
        sx={{
          width: '1200px',
          margin: '0 auto', // 중앙 정렬
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: ['0.5rem 1rem'], // 상하 패딩 제거, 좌우 여유
        }}
      >
        <Link to="/">
          <img
            src="/glo.png"
            alt="glo"
            sx={{
              width: ['100px', '160px', '160px'],
              maxWidth: '250px',
            }}
          />
        </Link>

        <nav
          sx={{
            display: ['flex', 'flex', 'flex'],
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: ['17px', '22px', '25px'],
            fontWeight: 500,
            marginLeft: '32px',
          }}
        >
          {NAV_LIST.map((value, index) => {
            return (
              <Link
                to={value.url}
                key={index}
                sx={{
                  fontSize: '15px',
                  color: 'black',
                  marginRight: '30px',
                }}
              >
                {value.title}
              </Link>
            )
          })}
        </nav>

        <div sx={{ display: ['none', 'flex'], alignItems: 'center' }}>
          <button
            onClick={handleButtonClick}
            sx={{
              fontSize: '1rem',
              backgroundColor: '#f04b4c',
              border: 'none',
              borderRadius: '5px',
              padding: '1px 3px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              sx={{
                color: '#fff',
                textAlign: 'center',
                fontWeight: 600,
                padding: '2px',
              }}
            >
              面談予約
              <br />
              <span sx={{ color: '#fff', display: 'flex' }}>※30秒で完了</span>
            </div>
          </button>
        </div>
      </header>
    </div>
  )
}

export default Head
