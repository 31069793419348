/** @jsx jsx */
import { Box, jsx } from 'theme-ui'

const Footer = () => {
  return (
    <footer
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '10px 0',
        backgroundColor: '#666666',
      }}
    >
      <p sx={{ color: '#989898', fontSize: '0.5rem' }}>
        Copyright (C) {new Date().getFullYear()} engibiz All Rights Reserved.
      </p>
    </footer>
  )
}

export default Footer
